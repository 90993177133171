@import "../../styles/helpers";
.popup_container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #1011102e;
    overflow: auto;
    padding: 20px;
    .popup_width {
        width: 500px;
        .resp_text {
            color: #254282;
            font-weight: 600;
            font-size: 28px;
            line-height: 22px; }
        .failed_text {
            @include lato;
            text-align: center;
            color: #333333;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px; } }

 }    // width: 500px

.outer {
    width: 600px;
    .btn {
        display: none; } }






































































// .homepanItems
//     display: flex;
//     position: absolute;
//     top: 12%;
//     padding-top: 12%;
//     width: 100%
//     justify-content: space-around
//     height: 100vh
//     background-image: url('/assests/Images/panbg.jpg')
//     background-size: 100%
//     background-repeat: no-repeat
//     .panel
//         display: flex
//         width: 500px;
//         height: 500px;
//         justify-content: center
//         flex-wrap: nowrap;
//         align-items: center
//     .cardHed

//     .signHead
//         color: white
//     .signIn
//         max-height: 500px
//     .panLogin
//         min-height: calc(72px - 500px)
// .panFooter
//     max-width: 20%
