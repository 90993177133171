@import "../../../../styles/helpers";

.head {
    margin-bottom: 44px;
    @include t {
        margin-bottom: 32px; }
    @include m {
        flex-wrap: wrap;
        margin-bottom: 24px; }
    .form {
        @include t {
            width: 300px; }
        @include m {
            width: 100%;
            margin-top: 16px;
            order: 3; } } }

.title {
    margin-right: 24px; }

.sorting {
    display: flex;
    margin-left: auto;
    gap: 10px; }

.link {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    svg {
        fill: $shades1;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $n7;
            @include dark {
                fill: $n1; } } }
    &.active {
        background: $n1;
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
        svg {
            fill: $n7; }
        @include dark {
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.05);
            background: $n8;
            svg {
                fill: $n1; } } }
    &:not(:last-child) {
        margin-right: 12px; } }

.list {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -12px 0;
    @include a {
        display: block;
        margin: 0; } }

.product {
    flex: 0 0 calc(33.333% - 24px);
    width: calc(33.333% - 24px);
    margin: 32px 12px 0;
    @include t {
        flex: 0 0 calc(50% - 24px);
        width: calc(50% - 24px); }
    @include a {
        width: 100%;
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 32px; } } }

.foot {
    margin-top: 44px;
    text-align: center;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }


.actionBtn {
    margin-right: 0px; }
.actionBtnAdmin {
    margin-right: 15px; }

.outerdelete {
    width: 500px;
    @include s {
        width: 100%; } }

.deleteh4 {
    font-size: 18px;
    margin-bottom: 0.9rem; }

.deletebutton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 65px;
    background: $p1;
    border-radius: 12px;
    @include button1;
    text-align: center;
    color: $n1;
    transition: all .2s;
    &:hover {
        background: darken($p1, 10); }
    &.disabled {
        opacity: .5;
        pointer-events: none; }
    svg {
        fill: $n1;
        transition: all .2s; }
    &:not([class^="button-square"]) {
        svg {}
        &:first-child {
            margin-right: 8px; }
        &:last-child {
            margin-left: 8px; } } }

.deletebutton1 {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 65px;
    background: #2a85ffd6;
    border-radius: 12px;
    @include button1;
    text-align: center;
    color: $n1;
    transition: all .2s;

    &:hover {
        svg {}
        // transform: rotate(90deg)
        background: darken($p1, 10); }
    svg {
        fill: $n1;
        transition: all .2s; }
    &:not([class^="button-square"]) {
        svg {}
        &:first-child {
            margin-right: 8px; }
        &:last-child {
            margin-left: 0px; } } }

.deletediv {
    display: flex;
    justify-content: center;
    gap: 20px; }

.fielddelete {
    width: 300px;
    @include m {
        width: 100%; } }
