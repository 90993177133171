@import "../../../../../styles/helpers";

.container {
    margin-top: 60px;
    overflow-y: scroll;
    height: 450px;
    &::-webkit-scrollbar {
        width: 10px; }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px; }
    &::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px; } }

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    flex: 0 0 100%;
    width: 100%;
    padding-right: 8px;
    @include d {
        flex: 0 0 calc(100% - 296px);
        width: calc(100% - 296px); }
    @include t {
        width: 100%;
        padding: 0; } }


.card {
    &:not(:last-child) {
        margin-bottom: 8px; } }

.rowField {
    margin-bottom: 30px; }

.editor {
    &:not(:last-child) {
        margin-bottom: 32px;
        @include d {
            margin-bottom: 24px; } } }

.group {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: 0 -6px -12px;
    @include m {
        display: block;
        margin: 0; }
    .field {
        flex: 0 0 calc(50% - 12px);
        width: calc(50% - 12px);
        margin: 0 6px 12px;
        @include m {
            width: 100%;
            margin: 12px 0 0; } } }

.field {
    text-transform: capitalize;
    &:not(:last-child) {
        margin-bottom: 32px;
        @include d {
            margin-bottom: 24px; } } }

.buttonContainer {
    display: flex;
    justify-content: center;
    width: 100%; }

.button {
    width: 100%;
    @include m {
        margin-right: 8px;
        flex-grow: 1; } }
