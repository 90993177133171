// $input-width: 40px
// $input-height: 40px
// $input-font-size: 20px

// .cusinput
//     input[type="text"]
//     width: $input-width
//     height: $input-height
//     font-size: $input-font-size
//     text-align: center
//     border: 2px solid #ccc
//     border-radius: 5px
//     margin: 5px
//     outline: none

//     &:focus
//         border: 2px solid #007bff
@import "../../styles/helpers";
$shadeEmail: #063772;

.otp_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    input {
        width: 42px;
        height: 42px;
        background: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 1px solid #B8B8B8;
        border-left: 0;
        border-right: 0;
        border-top: 0;
        text-align: center;
        font-weight: 600;
        @include dark {
            color: $n; } }
    &:focus {
        border-color: #007bff;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); } }

.login {
    display: flex;
    justify-content: center;
    padding: 48px;
    background: $n1;
    @include m {
        padding: 24px; }
    @include dark {
        background: $n7; } }

.wrapper {
    max-width: 300px;
    @include m {
        max-width: 100%; } }
.fpassword {
    color: gray;
    float: right;
    margin: 0px 0px -10px 0px;
    font-size: 12px;
    @include lato;
    &:hover {
        @include inter;
        text-decoration: underline;
        cursor: pointer; } }

.logo {
    display: inline-block;
    width: 100%;
    margin: 5px 0px;
    img {
        width: 100%; } }

.title {
    margin-bottom: 32px;
    text-align: center; }

.head {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 2px solid $n3;
    @include dark {
        border-color: $n6; } }

.subtitle {
    margin-bottom: 20px;
    @include body2-s; }

.btns {
    display: flex;
    margin: 0 -4px;
    .button {
        flex: 0 0 calc(50% - 8px);
        width: calc(50% - 8px);
        margin: 0 4px;
        padding: 0 16px;
        img {
            margin-right: 10px; } } }

.body {
    margin-top: 50px;
    .button {
        margin: 20px 0px;
        width: 100%; } }

.field {
    margin-bottom: 12px; }

.note {
    margin: 30px 0px;
    color: $shadeEmail;
    text-align: center;
    @include poppin;
    @include dark {
        color: $n; } }

.info {
    margin-top: 32px;
    @include caption1;
    color: $n4;
    a {
        font-weight: 700;
        color: $n7;
        transition: color .2s;
        @include dark {
            color: $n1; }
        &:hover {
            color: $p1; } } }
.pic {
    width: 400px;
    height: auto; }

#loginmodal {
    width: 70%; }

.title1 {
    text-align: center; }
