@import "../../styles/helpers";

.tile {
    display: flex;
    margin-bottom: 32px;
    padding: 8px;
    background: #F4F4F4;
    border-radius: 20px;

    .tileBody {
        flex: 0 0 50%;
        border-radius: 12px;
        color: #1A1D1F;
        @include m {
            display: block; } } }
