@import "../../../styles/helpers";

.section {
    .cus_card {
        margin-bottom: 20px; }
    .setup_dropd {
        display: flex;
        justify-content: {}
        gap: 20px;
        @include s {
            display: flex;
            flex-direction: column; }

        .datepick {
            height: 48px;
            padding: 0 48px 0 12px;
            border-radius: 12px;
            box-shadow: inset 0 0 0 2px #efefef;
            font-size: 14px;
            font-weight: 600;
            line-height: 48px;
            color: #1A1D1F;
            cursor: pointer;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-user-select: none;
            user-select: none;
            transition: all .2s;
            height: 40px;
            padding: 0 16px;
            line-height: 40px;
            @include s {
                width: 100%; }
            @include dark {
                background: $n6;
                box-shadow: inset 0 0 0 2px $n6;
                background: none;
                color: $n; } }
        .disab {
            opacity: 0.5; }

        // .cus_dropDown
        //     width: 25%
        //     +s
        //         width: 100%
        // .cus_input
        //     width: 30%
        //     +s
        //         width: 100%
        .head {
            display: flex;
            margin-left: auto;
            gap: 5px; } } }
.title {
    margin-right: 24px; }

.sorting {
    display: flex;
    margin-left: auto;
    gap: 10px; }


