@import "../../../styles/helpers";

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    flex: 0 0 100%;
    width: 100%;
    padding-right: 8px;
    @include d {
        flex: 0 0 calc(100% - 296px);
        width: calc(100% - 296px); }
    @include t {
        width: 100%;
        padding: 0; } }


.card {
    &:not(:last-child) {
        margin-bottom: 8px; } }

.rowField {
    margin-bottom: 20px; }

.pic {
    width: 250px;
    height: 200px;
    margin-top: -20px; }
