@import "../../styles/helpers";
.loader_bg {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(39, 39, 39, 0.726)
    background-color: #060606a8;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ldsring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 64px;
            height: 64px;
            margin: 8px;
            border: 8px solid #F4F4F4;
            border-radius: 50%;
            -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #F4F4F4 transparent transparent transparent;
            &:nth-child(1) {
                -webkit-animation-delay: -0.45s;
                animation-delay: -0.45s; }
            &:nth-child(2) {
                -webkit-animation-delay: -0.3s;
                animation-delay: -0.3s; }
            &:nth-child(3) {
                -webkit-animation-delay: -0.15s;
                animation-delay: -0.15s;
                @-webkit-keyframes lds-ring {
                        0% {
                            transform: rotate(0deg); }
                        100% {
                            transform: rotate(360deg); } }

                @keyframes  lds-ring {
                        0% {
                            transform: rotate(0deg); }
                        100% {
                            transform: rotate(360deg); } } } } }
    .loader_name {
            text-align: center;
            margin: 10px 10px;
            color: #FFFFFF;
            font-size: 18px;
            @include lato; } }


