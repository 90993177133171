@import "../../../../styles/helpers";

.Updatecredit {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   gap: 20px; }
.dropdown {
    width: 30%;
    @include s {
        width: 70%; } }
.button {
    @include s {
        width: 30%; } }
