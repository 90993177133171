@import  "../../styles/helpers";


.verify {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .verify_acc {
        background: #B5E4CA;
        border-radius: 12px;
        padding: 12px; } }
