.img_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%; }
  p {
    opacity: 0.7; }
  h3 {
    margin-top: 1rem; } }
