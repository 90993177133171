@import "../../../../styles/helpers";
.link {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    svg {
        fill: $shades1;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $n7;
            @include dark {
                fill: $n1; } } }
    &.active {
        background: $n1;
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
        svg {
            fill: $n7; }
        @include dark {
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.05);
            background: $n8;
            svg {
                fill: $n1; } } }
    &:not(:last-child) {
        margin-right: 12px; } }

.sorting {
    display: flex;
    margin-left: auto; }
