@import "../../../../styles/helpers";

.list {
    display: flex;
    margin: 0 -6px;
    flex-wrap: wrap;
    @include t {
        overflow: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px 2px;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none; }
        // &:before,
        // &:after
        //     content: ""
        //     flex-shrink: 0
        //     width: 24px
 }        //     height: 1px
    @include m {
        display: flex;
        margin: 0 18px;
        &:before,
        &:after {
            width: 16px; } }
    @include a {
        justify-content: center; } }

.item {
    display: block;
    flex: 0 0 calc(33.333% - 12px);
    width: calc(33.333% - 12px);
    margin: 0 6px;
    padding: 32px;
    border-radius: 12px;
    margin-bottom: 14px;
    color: $n7;
    @include t {
        flex: 0 0 295px;
        width: 268px;
        margin: 2px 12px; }
    @include m {
        text-align: center;
        flex: 0 0 270px;
        width: 270px; }
    @include dark {
        color: $n3; }
    &:nth-child(2n+1) {
        @include dark {
            background: rgba(#A7AAF7, .15) !important; } }
    &:nth-child(2n) {
        @include dark {
            background: rgba(#B1E5FC, .1) !important; } } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
    border-radius: 50%;
    background: $n7;
    svg {
        fill: $n1; }
    @include dark {
        background: $n1;
        svg {
            fill: $n7; } } }

.line {
    display: flex; }

.category {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    @include caption1;
    color: $n5;
    @include dark {
        color: $n3; } }

.tooltip {
    svg {
        fill: $n5;
        @include dark {
            fill: $n3; } } }

.counter {
    margin-bottom: 4px;
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -.03em; }

.indicator {
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    padding: 4px;
    background: $n1;
    color: $n4;
    @include dark {
        background: $n7; }
    span {
        margin-left: 4px;
        @include caption2; } }

.dashbrdDrpDwn {
    display: none;
    @include t {
        display: flex;
        gap: 20px;
        margin: 0px 0px 20px 10px; }
    @include s {
        gap: 7px;
        flex-direction: column;
        margin-bottom: 10px; } }

.dashbrdDrpDwnforDes {
    display: flex;
    gap: 20px;
    @include t {
        display: none; } }

.datepick {
    height: 48px;
    padding: 0 48px 0 12px;
    border-radius: 12px;
    box-shadow: inset 0 0 0 2px #efefef;
    font-size: 14px;
    font-weight: 600;
    line-height: 48px;
    color: #1A1D1F;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    user-select: none;
    transition: all .2s;
    @include s {
        width: 100%; }
    @include dark {
        background: $n6;
        box-shadow: inset 0 0 0 2px $n6;
        color: $n; } }
