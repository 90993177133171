@import "../../../styles/helpers";


.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    flex: 0 0 100%;
    width: 100%;
    padding-right: 8px;
    @include d {
        flex: 0 0 calc(100% - 296px);
        width: calc(100% - 296px); }
    @include t {
        width: 100%;
        padding: 0; } }


.card {
    &:not(:last-child) {
        margin-bottom: 8px; } }

.rowField {
    margin-bottom: 30px;
    .error {
        color: red;
        font-size: 12px;
        // // margin-top: -30px
 } }        // margin-left: 7px

.editor {
    &:not(:last-child) {
        margin-bottom: 32px;
        @include d {
            margin-bottom: 24px; } } }

.group {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: 0 -6px -12px;
    @include m {
        display: block;
        margin: 0; }
    .field {
        flex: 0 0 calc(50% - 12px);
        width: calc(50% - 12px);
        margin: 0 6px 12px;
        @include m {
            width: 100%;
            margin: 12px 0 0; } } }

.field {
    &:not(:last-child) {
        margin-bottom: 15px;
        @include d {
            margin-bottom: 15px; } } }

.buttonContainer {
    // margin-bottom: 30px
    display: flex;
    justify-content: flex-end;
    width: 100%; }

.button {
    @include m {
        margin-right: 8px;
        flex-grow: 1; } }


.serverDown {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background:#1a1d1fdb {}
    z-index: 999999 {
 }        // position: relative
    .serverDownMsg {
        background: #e4e4e4;
        border-radius: 12px;
        padding: 12px; }
    .serverMsgBtn {
        margin-top: 20px; } }

.container {
    margin-top: 60px;
    overflow: auto;
    height: auto;
    &::-webkit-scrollbar {
        width: 10px; }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px; }
    &::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px; } }

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    flex: 0 0 100%;
    width: 100%;
    padding-right: 8px;
    @include d {
        // flex: 0 0 calc(100% - 296px)
        width: calc(100% - 296px); }
    @include t {
        width: 100%;
        padding: 0; } }
.wrapper {
    padding-bottom: 24px;
    background: $n1;
    border-radius: 8px;
    @include dark {
        background: $n7; }
    @include m {
        overflow-x: scroll; } }

.table {
    display: table;
    width: 100%; }

.rowTable {
    display: table-row;
    &:first-child {
        .colTable {
            padding-top: 16px;
            padding-bottom: 16px;
            @include caption2;
            font-weight: 500;
            color: $n4; } }
    &:nth-child(2n) {
        .colTable {
            background: rgba($n3, .35);
            @include dark {
                background: rgba($n6, .3); } } } }

.colTable {
    display: table-cell;
    padding: 10px 16px;
    @include base2;
    &:first-child {
        padding-left: 24px;
        white-space: nowrap;
        color: $n4;
        @include m {
            padding-left: 16px; } }
    &:nth-child(2) {
        @include m {
            display: none; } }
    &:last-child {
        padding-right: 24px;
        cursor: pointer;
        @include m {
            padding-right: 16px; } } }

.status {
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    text-transform: capitalize; }

.paginationBar {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px; }

.emailSuccess {
    width: 600px; }
