@import "../../styles/helpers";
.success {
    padding: 25px 0 0;
    text-align: center;
    @include m {
        padding: 32px 0 16px; } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 128px;
    margin: 0 auto 20px;
    border-radius: 50%;
    background: $s4;
    font-size: 48px; }

.info {
    margin-bottom: 8px;
    @include title1-s;
    color: $n5;
    @include dark {
        color: $n3; } }

.price {
    margin-bottom: 16px;
    @include m {
        font-size: 40px; } }

.text {
    margin-bottom: 20px;
    color: $n4;
    span {
        color: $n7;
        margin-left: 10px;
        cursor: copy;
        @include dark {
            color: $n1; } } }


