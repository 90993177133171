@import "../../../../../styles/helpers";

.container {
    margin-top: 20px;
    height: 473px;
    &::-webkit-scrollbar {
        width: 6px; }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px; }
    &::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px; } }

.scroll {
    overflow-y: scroll; }
.scrolladmin {
    overflow-y: hidden;
    @include m {
        overflow-y: scroll; } }
.row {
    display: flex;
    // overflow-y: hidden
    margin-right: 8px;
    @include t {
        display: block; } }
.rowAdmin {
    display: flex;
    overflow-y: hidden;
    @include t {
        display: block; }
    @include m {
        margin-right: 8px; } }

.col {
    width: 100%;
    padding-right: 3px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 30px;
    @include m {
        display: block;
        grid-template-columns: none;
        width: 100%;
        padding: 0;
        gap: 0; } }
.colAdmin {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 30px;
    @include m {
        display: block;
        grid-template-columns: none;
        width: 100%;
        padding: 0;
        gap: 0; } }

.card {
    &:not(:last-child) {
        margin-bottom: 8px; } }

.rowField {
    width: 100%;
    margin-bottom: 20px;
    @include m {
        margin: 10px 0px; }
    .createErr {
        color: red;
        font-size: 12px; }
    select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none; } }


.add_balance {
    position: relative;
    display: inline-block;
    button {
        position: absolute;
        top: 53%;
        right: 16px;
        width: 16px;
        cursor: pointer;
        @include t {
            top: 53%; } } }

.editor {
    &:not(:last-child) {
        margin-bottom: 32px;
        @include d {
            margin-bottom: 24px; } } }

.group {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: 0 -6px -12px;
    @include m {
        display: block;
        margin: 0; }
    .field {
        flex: 0 0 calc(50% - 12px);
        width: calc(50% - 12px);
        margin: 0 6px 12px;
        @include m {
            width: 100%;
            margin: 12px 0 0; } } }

.field {
    text-transform: capitalize;
    &:not(:last-child) {
        margin-bottom: 3px;
        @include d {
            margin-bottom: 3px; } } }


.buttonContainer {
    display: grid;
    grid-column: span 3;
    justify-content: center;
    width: 100%; }

.rowWallet {
    margin: 0px 0px 10px 0px;
    grid-column: span 3; }

.button {
    width: 100%;
    margin-top: 10px;
    @include m {
        margin-right: 8px;
        flex-grow: 1; } }

.asteriskRed {
    color: red;
    font-size: 18px;
    margin-left: 4px; }

.input {
    width: 100%;
    height: 48px;
    padding: 0 10px;
    border-radius: 12px;
    border: 2px solid transparent;
    background: $n2;
    @include inter;
    @include base1-s;
    color: $n7;
    transition: all .2s;
    @include dark {
        border-color: $n6;
        background: $n6;
        color: $n1; }
    @include placeholder {
        color: $n4;
        @include dark {
            color: $shades1; } }
    &:focus {
        border-color: $shades1;
        background: $n;
        @include dark {
            border-color: $n5;
            background: $n8; } } }

.rowFieldremarks {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 7px;
    @include m {
        margin: 10px 0px; }
    .createErr {
        color: red;
        font-size: 12px; } }
.button:disabled {
    opacity: 0.5; }
