@import "../../../styles/helpers";

.login {
    display: flex;
    justify-content: center;
    padding: 40px 10px 10px;
    background: $n1;
    @include m {
        padding: 24px; }
    @include dark {
        background: $n7; } }

.wrapper {
    max-width: 300px;
    @include m {
        max-width: 100%; } }
.fpassword {
    color: gray;
    float: right;
    margin: 5px 5px; }

.logo {
    display: inline-block;
    width: 100%;
    margin: 5px 0px;
    img {
        width: 100%; } }

.title {
    margin-bottom: 32px;
    text-align: center; }

.head {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 2px solid $n3;
    @include dark {
        border-color: $n6; } }

.subtitle {
    margin-bottom: 20px;
    @include body2-s; }

.btns {
    display: flex;
    margin: 0 -4px;
    .button {
        flex: 0 0 calc(50% - 8px);
        width: calc(50% - 8px);
        margin: 0 4px;
        padding: 0 16px;
        img {
            margin-right: 10px; } } }

.body {
    .button {
        width: 100%; } }

.field {
    margin-bottom: 12px; }

.note {
    margin-top: 32px;
    @include body2-s;
    color: $shades1;
    display: flex;
    justify-content: center; }

.backbtn {
    text-align: center;
    margin-top: 40px; }

.emailSuccess {
    width: 500px !important; }


.info {
    margin-top: 32px;
    @include caption1;
    color: $n4;
    a {
        font-weight: 700;
        color: $n7;
        transition: color .2s;
        @include dark {
            color: $n1; }
        &:hover {
            color: $p1; } } }
.pic {
    width: 400px;
    height: auto; }

#loginmodal {
    width: 70%; }

.title1 {
    text-align: center; }
.titleForgot {
    text-align: left; }
.title2 {
    font-size: 13px;
    font-weight: 400;
    color: black; }

.Otpbody {
    .Otpnote {
        margin-top: 32px;
        @include body2-s;
        color: $shades1; } }

.fieldforgot {
    margin: 14px 0; }

.loginModelErr {
        color: red;
        font-size: 10px;
        margin: -12px 0 5px 0; }
.sessionPopup {
    width: 850px !important; }
.sessionPopupBtn {
    display: none;
 }    // width: 500px !important
